/* eslint-disable no-unused-vars */
import { initializeApp } from 'firebase/app'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, EmailAuthProvider } from "firebase/auth"
import {
  getFirestore,
  collection,
  addDoc,
  query,
  orderBy,
  where,
  limit,
  // onSnapshot,
  // setDoc,
  updateDoc,
  doc,
  serverTimestamp,
  getDocs,
  getDoc,
  setDoc
} from 'firebase/firestore'
import * as firebaseui from 'firebaseui'
import 'firebase/auth'  
import 'firebaseui/dist/firebaseui.css'

import firebaseConfig from './config.js'
import store from '@/store'

export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
export const auth = getAuth()
export const db = getFirestore(app)
export const functions = getFunctions(app)

export const timestamp = serverTimestamp
export const getFunctionForCall = functionName => httpsCallable(functions, functionName)

// if (location.hostname === "localhost") {
//   connectFunctionsEmulator(functions, "localhost", 5001)
// }

export const uiConfig = {
  signInSuccessUrl: '/dashboard',
  signInOptions: [{
    provider: EmailAuthProvider.PROVIDER_ID,
    disableSignUp: {
      status: true,
    },
  }],
  tosUrl: '<your-tos-url>'
}
export const ui = new firebaseui.auth.AuthUI(auth)

const exampleData = index => ({
  createdAt: timestamp(),
  updatedAt: timestamp(),
  deletedAt: null,
  info: {
    type: 'contact',
    role: 'user',
    email: `wash${index}@serenity.io`,
    phone: '89128473884',
    name: `Katrina ${index}`,
    surname: 'Warren',
    avatar: `https://randomuser.me/api/portraits/women/${index}.jpg`,
    socialProfiles: [
      {
        id: 'twitter',
        link: 'http://twitter.com/th1sland'
      }
    ],
    markedEmailAsSpam: false,
    unsubscribedFromEmails: false,
    lastSeenAt: timestamp(),
    location: {
      country: 'Ireland',
      region: 'Dublin',
      city: 'Dublin',
      countryCode: 'IRL',
      continentCode: 'EU'
    }
  }
})

const exampleEventData = ({ event, userId }) => {
  return {
    "userId": userId,
    "type": "email",
    "context": {
      "page": {
        "path": "/zenwriter/",
        "referrer": "",
        "search": "",
        "title": "Zenwriter",
        "url": "https://zenwriter.com/"
      },
      "userAgent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2490.86 Safari/537.36",
      "ip": "108.0.78.21"
    },
    "event": event,
    "messageId": "ajs-f8ca1e4de5024d9430b3928bd8ac6b96",
    "properties": {
      "title": "Intro to Analytics"
    },
    "receivedAt": timestamp(),
    "sentAt": timestamp(),
    "createdAt":  timestamp(),
    "originalTimestamp": timestamp()
  }
}

const getCollectionRef = collectionName => collection(db, collectionName)
const getDocRef = (collectionName, docId) => doc(db, collectionName, docId) 

export const getDocsFromCollection = async collectionName => {
  const snapshot = await getDocs(getCollectionRef(collectionName))
  const result = snapshot.docs.map(doc => [doc.id, doc.data()])

  return result
}

export const getDocsFromCollectionByQuery = async (collectionName, queryParams = {}, options = {}) => {
  const collectionRef = getCollectionRef(collectionName)
  const { where: whereParam = {}, orderBy: orderByParam = {}, limit: limitParam } = queryParams
  
  const wherePredicats = Object.entries(whereParam).map(entry => where(entry[0], entry[1].operator, entry[1].operand))
  const orderByPredicats = Object.entries(orderByParam).map(entry => orderBy(entry[0], entry[1].direction))
  const resultArguments = [collectionRef, ...wherePredicats,...orderByPredicats]

  const snapshot = await getDocs(query(...resultArguments))
  const result = snapshot.docs.map(doc => [doc.id, doc.data()])

  return result
}

export const getDocFromCollection = async (collectionName, docId) => {
  const docRef = getDocRef(collectionName, docId)
  const snapshot = await getDoc(docRef)

  if (!snapshot.exists()) {
    console.log(`Document isn't exists!`)
    return null
  }

  return [snapshot.id, snapshot.data()]
}

export const addDocToCollection = async (collectionName, docData = {}) => {
  const  collectionRef = getCollectionRef(collectionName)

  await addDoc(collectionRef, docData)
  return docData
}

export const updateDocInCollection = async (collectionName, docId, updates = {}) => {

  if (!collectionName || !docId) return null
  const docRef = doc(db, collectionName, docId)
  const response = await updateDoc(docRef, updates)
  const updatedDoc = await getDocFromCollection(collectionName, docId)

  return updatedDoc
}

// setTimeout(async () => {
//   const customersData= await getDocsFromCollection('customers')
//   const customersIds = customersData.map(customer => customer[0])
//   const events = ['Email Delivered', 'Email Opened', 'Unsubscribed']

//   customersIds.forEach(id => {
//     const countEvent = Math.floor(Math.random() * 4) + 1
//     const needEvents = events.slice(0, countEvent).map(event => {
//       return {
//         event,
//         userId: id
//       }
//     })
//     const eventsData = needEvents.map(exampleEventData)

//     Promise.all(eventsData.map(event => {
//       return addDocToCollection('events', event)
//     }))
//       .then(() => {
//         console.log(id, needEvents.map(event => event.event))
//       })
//   })
// }, 3000)

export const getStorageDownloadURL = path => {
  return getDownloadURL(ref(storage, path))
  .then(url => {
    return url
  })
  .catch(error => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        break
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break
      case 'storage/canceled':
        // User canceled the upload
        break
      case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        break
    }
  })
}