import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

export const DASHBOARD_PATH = `/dashboard`
export const DASHBOARD_USERS = `${DASHBOARD_PATH}/user`
export const DASHBOARD_USERS_ALL = `${DASHBOARD_USERS}/all`
export const DASHBOARD_USER = `${DASHBOARD_USERS}/:userId`
export const DASHBOARD_CUSTOMER = `${DASHBOARD_USER}/customer/:customerId`
export const DASHBOARD_SUBSCRIPTION = `${DASHBOARD_CUSTOMER}/subscription/:subscriptionId`
export const DASHBOARD_ORDER = `${DASHBOARD_SUBSCRIPTION}/order/:orderId`
export const DASHBOARD_PRODUCTS = `${DASHBOARD_PATH}/products`
export const DASHBOARD_PRODUCT = `${DASHBOARD_PRODUCTS}/:productId`

const routes = [
  {
    path: DASHBOARD_PATH,
    name: 'Dashboard',
    redirect: `${DASHBOARD_PATH}/home`,
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/dashboard'),
    children: [
      {
        path: `${DASHBOARD_PATH}/home`,
        name: 'DashboardHome',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/views/dashboard/home')
      },
      {
        path: DASHBOARD_USERS,
        name: 'DashboardUsers',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/views/dashboard/users/index'),
        redirect: DASHBOARD_USERS_ALL,
        children: [
          {
            path: DASHBOARD_USERS_ALL,
            meta: {
              requiresAuth: true
            },
            component: () => import('@/views/dashboard/users/all')
          },
          {
            path: DASHBOARD_USER,
            meta: {
              requiresAuth: true
            },
            component: () => import('@/views/dashboard/users/user'),
          },
          {
            path: DASHBOARD_CUSTOMER,
            meta: {
              requiresAuth: true
            },
            component: () => import('@/views/dashboard/customer'),
          },
          {
            path: DASHBOARD_SUBSCRIPTION,
            component: () => import('@/views/dashboard/subscription')
          },
          {
            path: DASHBOARD_ORDER,
            component: () => import('@/views/dashboard/order')
          }
        ]
      },
      {
        path: DASHBOARD_PRODUCTS,
        meta: {
          requiresAuth: true
        },
        component: () => import('@/views/dashboard/products')
      },
      {
        path: DASHBOARD_PRODUCT,
        meta: {
          requiresAuth: true
        },
        component: () => import('@/views/dashboard/product')
      },
      {
        path: '/dashboard/settings',
        name: 'DashboardSettings',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/views/dashboard/settings')
      }
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/auth')
  },
  {
    path: '*',
    redirect: '/dashboard'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { initedApp } = store.state
  if (!initedApp) {
    const timeout = setInterval(() => {
      if (store.getters.initedApp) {
        clearInterval(timeout)
        const isAuthorized = !!store.getters.user
        if (isAuthorized) {
          const { name } = to
          if (name === 'Auth') {
            router.replace({ path: '/dashboard' })
          } else {
            next()
          }
        } else {
          router.replace({ path: '/auth'})
        }
      }
    }, 100)
  } else {
    next()
  }
})

export default router
