import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import  { getFunctionForCall, getDocsFromCollection, getDocFromCollection, updateDocInCollection, timestamp } from '@/fb'

/* eslint-disable no-new */
const store = new Vuex.Store({
  modules: {
  },
  state: () => ({
    initedApp: false,
    user: null
  }),
  actions: {
    async getCustomer(context, userId) {
      const res = await getDocFromCollection('customers', userId)
      return res
    },
    async searchInCustomers(context, email) {
      const func = getFunctionForCall('rpcQueryTwoCheckout')
      const response = await func({
        action: 'searchCustomers',
        params: {
          Pagination: {
            Limit: 100
          },
          Email: email
        }
      })
      const { Items = [] } = response.data.result

      return Items || []
    },
    async getSubscriptionHistory(context, subscriptionId) {
      const response = await getFunctionForCall('rpcQueryTwoCheckout')({
        action: 'getSubscriptionHistory',
        params: subscriptionId
      })

      return response.data && response.data.result || []
    },
    async getProducts() {
      const response = await getDocsFromCollection('products')
      return response
    },
    async fetchRates() {
      const res = await getFunctionForCall('curl')({
        method: 'get',
        url: `https://secure.2checkout.com/content/exchange-json.php`,
      })
      console.log(res)
      return res
    },
    async getProduct(context, productId) {
      const res = await getDocFromCollection('products', productId)
      // const response = await getFunctionForCall('rpcQueryTwoCheckout')({
      //   action: 'getProductByCode',
      //   params: res[1].externalIds.verifone
      // })
      // console.log(response.data.result)
      return res
    },
    async getOrder(context, orderId) {
      const response = await getFunctionForCall('rpcQueryTwoCheckout')({
        action: 'getOrder',
        params: orderId
      })

      return response.data && response.data.result || []
    }, 
    async getCustomerSubscriptions(context, customerId) {
      const response = await getFunctionForCall('rpcQueryTwoCheckout')({
        action: 'getCustomerSubscriptions',
        params: customerId
      })

      return response.data && response.data.result || []
    },
    async synchronizeUser(context, user = '') {
      if (!user) return
      const [userDocId , userData ] = user
      const { email } = userData.info

      console.log(`Start synchronize ${email}...`)

      const customers = await context.dispatch('searchInCustomers', email) || []
      console.log('Customers: ', customers)

      const subscriptionsResponse = await Promise.all(customers.map( ({ CustomerReference }) => context.dispatch('getCustomerSubscriptions', CustomerReference)))
      const subscriptions = subscriptionsResponse.reduce((acc, subscriptions) => {
        acc = [...acc, ...subscriptions]
        return acc
      }, [])
      console.log('Subscriptions: ', subscriptions)

      const historyResponse = await Promise.all(subscriptions.map( ({ SubscriptionReference }) => context.dispatch('getSubscriptionHistory', SubscriptionReference)))
      const subscriptionsHistory = historyResponse.reduce((acc, orders) => {
        acc = [...acc, ...orders]
        return acc
      }, [])

      const orders = await Promise.all(subscriptionsHistory.map(order => context.dispatch('getOrder', order.ReferenceNo)))

      const prepareData = (arr = [], key) => {
        const result = arr.reduce((acc, item) => {
          acc = {
            ...acc,
            [item[key]]: item
          } 

          return acc
        }, {})

        return result
      }

      const externalData = {
        verifone: {
          customers: prepareData(customers, 'CustomerReference'),
          subscriptions: prepareData(subscriptions, 'SubscriptionReference'),
          subscriptionsHistory: prepareData(subscriptionsHistory, 'ReferenceNo'),
          orders: prepareData(orders, 'RefNo')
        }
      }

      await updateDocInCollection('customers', userDocId, {
        externalData,
        updatedAt: timestamp()
      })

      console.log(`Synchronized ${email}!`, externalData)

    },
  },
  mutations: {
    setUser(state, val) {
      state.user = val
    },
    setInitedApp(state, val){
      state.initedApp = val
    }
  },
  getters: {
    user: state => state.user,
    initedApp: state => state.initedApp
  }
})

export default store
